import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Specialty } from  '../models/specialty';
import { Observable } from  'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpecialtyService {
  //PHP_API_SERVER = "http://127.0.0.1:8080";
  // PHP_API_SERVER = "http://localhost/angular/php-api/backend"; // localhost
  PHP_API_SERVER = "https://db.webineering.com.au/farmfresh"; // remote host

  // -----------------------------------------------------------------------------------
  // API services ...
  readSpecialties(): Observable<Specialty[]>{
    return this.httpClient.get<Specialty[]>(`${this.PHP_API_SERVER}/api/ffresh-spec/read.php`);
  }

  createSpecialty(specialty: Specialty): Observable<Specialty>{
    return this.httpClient.post<Specialty>(`${this.PHP_API_SERVER}/api/ffresh-spec/create.php`, specialty);
  }

  updateSpecialty(specialty: Specialty){
    return this.httpClient.put<Specialty>(`${this.PHP_API_SERVER}/api/ffresh-spec/update.php`, specialty);   
  }

  deleteSpecialty(id: number){
    return this.httpClient.delete<Specialty>(`${this.PHP_API_SERVER}/api/ffresh-spec/delete.php/?id=${id}`);
  }
  // -----------------------------------------------------------------------------------

  constructor(private httpClient: HttpClient) { }
}
