import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GlutenfreeService } from '../services/glutenfree.service';
import { Glutenfree } from  '../models/glutenfree';

@Component({
  selector: 'app-glutenfree',
  templateUrl: './glutenfree.component.html',
  styleUrls: ['./glutenfree.component.css']
})
export class GlutenfreeComponent implements OnInit {

  glutenfrees!:  Glutenfree[];
  selectedGlutenfree:  Glutenfree  = { id:null as any, product:null as any, description:null as any};

  constructor(private glutenfreeService: GlutenfreeService) { }

  ngOnInit() {
    this.glutenfreeService.readGlutenfrees().subscribe((glutenfrees: Glutenfree[])=>{
    this.glutenfrees = glutenfrees;
    console.log(this.glutenfrees);
    })
  }
  createOrUpdateGlutenfree(form:any){
    if(this.selectedGlutenfree && this.selectedGlutenfree.id){
      form.value.id = this.selectedGlutenfree.id;
      this.glutenfreeService.updateGlutenfree(form.value).subscribe((glutenfree: Glutenfree)=>{
        console.log("Glutenfree updated" , glutenfree);
      });
    }
    else{

      this.glutenfreeService.createGlutenfree(form.value).subscribe((glutenfree: Glutenfree)=>{
        console.log("Glutenfree created, ", glutenfree);
      });
    }

  }

  selectGlutenfree(glutenfree: Glutenfree){
    this.selectedGlutenfree = glutenfree;
  }

  deleteGlutenfree(form:any){
    if(this.selectedGlutenfree && this.selectedGlutenfree.id){
      form.value.id = this.selectedGlutenfree.id;
      this.glutenfreeService.deleteGlutenfree(this.selectedGlutenfree.id).subscribe((glutenfree: Glutenfree)=>{
        console.log("Glutenfree deleted, ", glutenfree);
      });
    }
  }

}
