import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SpecialtyService } from '../services/specialty.service';
import { Specialty } from  '../models/specialty';

@Component({
  selector: 'app-specialty',
  templateUrl: './specialty.component.html',
  styleUrls: ['./specialty.component.css']
})
export class SpecialtyComponent implements OnInit {

  specialties!:  Specialty[];
  selectedSpecialty:  Specialty  = { id:null as any, product:null as any, description:null as any};

  constructor(private specialtyService: SpecialtyService) { }

  ngOnInit() {
    this.specialtyService.readSpecialties().subscribe((specialties: Specialty[])=>{
    this.specialties = specialties;
    console.log(this.specialties);
    })
  }
  createOrUpdateSpecialty(form:any){
    if(this.selectedSpecialty && this.selectedSpecialty.id){
      form.value.id = this.selectedSpecialty.id;
      this.specialtyService.updateSpecialty(form.value).subscribe((specialty: Specialty)=>{
        console.log("Specialty updated" , specialty);
      });
    }
    else{

      this.specialtyService.createSpecialty(form.value).subscribe((specialty: Specialty)=>{
        console.log("Specialty created, ", specialty);
      });
    }

  }

  selectSpecialty(specialty: Specialty){
    this.selectedSpecialty = specialty;
  }

  deleteSpecialty(form:any){
    if(this.selectedSpecialty && this.selectedSpecialty.id){
      form.value.id = this.selectedSpecialty.id;
      this.specialtyService.deleteSpecialty(this.selectedSpecialty.id).subscribe((specialty: Specialty)=>{
        console.log("Specialty deleted, ", specialty);
      });
    }
  }

}
