import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { ContactComponent } from './contact/contact.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { HomeComponent } from './home/home.component';
import { P404Component } from './p404/p404.component';
import { SpecialtyComponent } from './specialty/specialty.component';
import { GlutenfreeComponent } from './glutenfree/glutenfree.component';

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    DeliveryComponent,
    SpecialtyComponent,
    GlutenfreeComponent,
    HomeComponent,
    P404Component,
    SpecialtyComponent,
    GlutenfreeComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  RouterModule.forRoot([
    {path: 'home', component: HomeComponent},
    {path: 'delivery', component: DeliveryComponent},
    {path: 'specialty', component: SpecialtyComponent},
    {path: 'glutenfree', component: GlutenfreeComponent},
    {path: 'contact', component: ContactComponent},
    {path: '', redirectTo: '/home', pathMatch: 'full'},
    {path: '**', component: P404Component}
  ]),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
