<div id="ffcarousel" class="carousel slide" data-ride="carousel">
  <!-- Indicators -->
  <ul class="carousel-indicators">
    <li data-target="#ff" data-slide-to="0" class="active"></li>
    <li data-target="#ff" data-slide-to="1"></li>
    <li data-target="#ff" data-slide-to="2"></li>
    <li data-target="#ff" data-slide-to="3"></li>
    <li data-target="#ff" data-slide-to="4"></li>
    <li data-target="#ff" data-slide-to="5"></li>
    <li data-target="#ff" data-slide-to="6"></li>
    <li data-target="#ff" data-slide-to="7"></li>
    <li data-target="#ff" data-slide-to="8"></li>
  </ul>
  <!-- The slideshow -->
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="assets/images/slider/1.jpg" alt="Shop Front">
				<div class="carousel-caption">
					<h1>Welcome to Farm Fresh Market</h1>
					<p>Online Orders available</p>
					<p><a class="btn btn-lg btn-success orderbtn" href="https://order.farmfreshmarket.net.au/onlineorder.php" role="button">Order Online</a>
				</div>
    </div>
    <div class="carousel-item">
      <img src="assets/images/slider/2.jpg" alt="Shop Front">
				<div class="carousel-caption">
					<h1>Murray Bridge Fruiterers & Greengrocers</h1>
					<p>Online Orders available</p>
					<p><a class="btn btn-lg btn-success orderbtn" href="https://order.farmfreshmarket.net.au/onlineorder.php" role="button">Order Online</a>
				</div>
    </div>
    <div class="carousel-item">
      <img src="assets/images/slider/3.jpg" alt="Shop Front">
				<div class="carousel-caption">
					<h1>$7 delivery in Murray Bridge</h1>
					<p><a class="btn btn-lg btn-success" href="/delivery" role="button">Learn more</a></p>
				</div>
    </div>
    <div class="carousel-item">
      <img src="assets/images/slider/5.jpg" alt="Shop Front">
				<div class="carousel-caption">
					<h1>Delivery from $9 throughout Murraylands & Upper SE</h1>
					<p><a class="btn btn-lg btn-success" href="/delivery" role="button">Learn more</a></p>
				</div>
    </div>
    <div class="carousel-item">
      <img src="assets/images/slider/6.jpg" alt="Shop Front">
				<div class="carousel-caption">
					<h1>Try our cafe bar for great coffee, snacks &amp; fruit smoothies</h1>
					<p><a class="btn btn-lg btn-success" href="assets/documents/menu.pdf" target="new" role="button">View Menu</a></p>
				</div>
    </div>
    <div class="carousel-item">
      <img src="assets/images/slider/7.jpg" alt="Shop Front">
				<div class="carousel-caption">
					<h1>Great selection of Nuts, Honey, Jams, Pasta & More</h1>
					<p><a class="btn btn-lg btn-success" href="/specialty" role="button">Learn more</a></p>
				</div>
    </div>
    <div class="carousel-item">
      <img src="assets/images/slider/5.jpg" alt="Shop Front">
				<div class="carousel-caption">
					<h1>Open Mon-Fri 7:30am-5:30pm, Sat 7:30am-12:30pm</h1>
					<p><a class="btn btn-lg btn-success" href="/contact" role="button">Learn more</a></p>
				</div>
    </div>
    <div class="carousel-item">
      <img src="assets/images/slider/8.jpg" alt="Shop Front">
				<div class="carousel-caption">
					<h1>Extensive range of Deli, Specialty, Meat & Smallgoods</h1>
					<p><a class="btn btn-lg btn-success" href="/specialty" role="button">Learn more</a></p>
				</div>
    </div>
  </div>
  <!-- Left and right controls -->
  <a class="carousel-control-prev" href="#demo" data-slide="prev">
    <span class="carousel-control-prev-icon"></span>
  </a>
  <a class="carousel-control-next" href="#demo" data-slide="next">
    <span class="carousel-control-next-icon"></span>
  </a>
</div>
<!-- // carousel -->

<!-- Marketing messaging and featurettes
================================================== -->
<!-- Wrap the rest of the page in another container to center all the content. -->

<div class="container marketing">

<!-- Three columns of text below the carousel -->
  <div class="row">
    <div class="col-lg-4">
        <img class="rounded-circle" src="assets/images/heading2.jpg" alt="Heading one" width="140" height="140">
        <p>We are your local supplier of fresh fruit, vegetables, condiments, bread &amp; flowers in the Murraylands.</p>
    </div><!-- /.col-lg-4 -->
    <div class="col-lg-4">
        <img class="rounded-circle" src="assets/images/heading1.jpg" alt="Heading one" width="140" height="140">
        <p>Our business has been operating for 25 years and we are proud to be a local employer, who supports our community.</p>
    </div><!-- /.col-lg-4 -->
    <div class="col-lg-4">
        <img class="rounded-circle" src="assets/images/heading3.jpg" alt="Heading one" width="140" height="140">
        <p>Take a look around our website and read about the products and services we provide to our customers.</p>
     </div><!-- /.col-lg-4 -->
  </div><!-- /.row -->
<!-- headline -->
  <div class="row">
     <div class="col-lg-12 headline">
				<p>If you would like any further information, please contact our enthusiastic staff on phone 8531 2888 or using the form on the contact us page. We look forward to hearing from you soon.</p>

				<p>Kind regards<br>
				Tracey and Carlo De Michele</p>
		</div>
	</div>
</div>

<!-- orderform -->
<div class="orderform">
 <div class="container">
  <div class="get-in-grids">
		<p>Online Orders available</p>
		<p><a class="btn btn-lg btn-success orderbtn" href="https://order.farmfreshmarket.net.au/onlineorder.php" role="button">Order Online</a>
  </div>
</div>
</div>
<!-- //orderform -->

<p>&nbsp;</p>
<p>&nbsp;</p>
<div class="container">
	<div class="row">
		<div class="col-md-12"><iframe width="100%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps/ms?msid=210324664347697622508.0004f74f8adb5bfb0d703&amp;msa=0&amp;hl=en&amp;ie=UTF8&amp;t=m&amp;z=13&amp;output=embed"></iframe>
		</div>
	</div>
</div>
