  <div class="row">
    <div class="col-sm-12 deliveries">
      <h2>Deliveries</h2>
      <p>Inside the Murray Bridge area home deliveries are between 8.00am and 1.00pm and cost $7.00. We deliver to business and families.</p>
      <p>Delivery outside Murray Bridge from $9.00 per box depending on the carrier chosen for delivery.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-5 delivery-left">
			<h2>Delivery Locations:</h2>
			<ul>
					<li>Mannum</li>
					<li>Meningie</li>
					<li>Lameroo</li>
					<li>Pinnaroo</li>
					<li>Tailem Bend</li>
					<li>Wellington</li>
					<li>Jervois</li>
					<li>Murrayville</li>
					<li>Keith (Tuesday &amp; Thursday)</li>
					<li>Tintinara</li>
					<li>Salt Creek</li>
					<li>Callington</li>
					<li>Kanmantoo</li>
				</ul>
    </div>
    <div class="col-sm-7 delivery-right">
      <p>
				<img src="/assets/images/map.jpg" alt="delivery map" width="70%">
      </p>
    </div>
  </div>
