<div class="comtainer">
  
  <div class="row">
    <div class="col-sm-12 specialty">
      <h2>Specialty Lines</h2>
    </div> <!-- // specialty -->
  </div> <!-- // row -->
  
  <div class="row">
    <div class="col-md-1">
    </div>
    <div class="col-md-5 divl">
      <div class="row prod-list-l" *ngFor="let spec of specialties;let i = index;">
        <div class="col prod-head" *ngIf="i%2 == 0">
          <h5>{{spec.product}}</h5>
          <div class="row">
            <div class="col-md-3 prod-img">
              <img src="../../assets/images/specialty/prod{{spec.id}}.jpg">
            </div>
            <div class="col-md-7 prod-descr">
              <p>{{spec.description}}</p>
            </div>
            <div class="col-md-2">
            </div>
          </div>
        </div>
      </div> <!-- // row ngFor -->
    </div> <!-- //col-md-6 -->
    <div class="col-md-5 divr">
      <div class="row prod-list-r" *ngFor="let spec of specialties;let i = index;">
        <div class="col prod-head" *ngIf="i%2 != 0">
          <h5>{{spec.product}}</h5>
          <div class="row">
            <div class="col-md-3 prod-img">
              <img src="../../assets/images/specialty/prod{{spec.id}}.jpg">
            </div>
            <div class="col-md-7 prod-descr">
              <p>{{spec.description}}</p>
            </div>
            <div class="col-md-2">
            </div>
          </div>
        </div>
      </div> <!-- // row ngFor -->
    </div> <!-- //col-md-6 -->
    <div class="col-md-1">
    </div>
  </div> <!-- // row -->

</div> <!-- // container-->
