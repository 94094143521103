import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Glutenfree } from  '../models/glutenfree';
import { Observable } from  'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlutenfreeService {
  //PHP_API_SERVER = "http://127.0.0.1:8080";
  // PHP_API_SERVER = "http://localhost/angular/php-api/backend"; // localhost
  PHP_API_SERVER = "https://db.webineering.com.au/farmfresh"; // remote host

  // -----------------------------------------------------------------------------------
  // API services ...
  readGlutenfrees(): Observable<Glutenfree[]>{
    return this.httpClient.get<Glutenfree[]>(`${this.PHP_API_SERVER}/api/ffresh-gf/read.php`);
  }

  createGlutenfree(glutenfree: Glutenfree): Observable<Glutenfree>{
    return this.httpClient.post<Glutenfree>(`${this.PHP_API_SERVER}/api/ffresh-gf/create.php`, glutenfree);
  }

  updateGlutenfree(glutenfree: Glutenfree){
    return this.httpClient.put<Glutenfree>(`${this.PHP_API_SERVER}/api/ffresh-gf/update.php`, glutenfree);   
  }

  deleteGlutenfree(id: number){
    return this.httpClient.delete<Glutenfree>(`${this.PHP_API_SERVER}/api/ffresh-gf/delete.php/?id=${id}`);
  }
  // -----------------------------------------------------------------------------------

  constructor(private httpClient: HttpClient) { }
}
