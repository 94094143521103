import { Component, OnInit } from '@angular/core';
//import validator and FormBuilder
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import {EmailService} from './../services/email.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  isShowDiv = false;
  ro = '';

  //contact form
  fullname: any;
  email: any;
  phone: any;
  comments: any;

  myForm: any;

  constructor(private fb: FormBuilder,private emailService:EmailService) { }

  ngOnInit() {
    this.myForm = this.fb.group({
      fullname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(15)]],
      comments: ['', [Validators.required, Validators.minLength(15)]],
    });
  }

  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

  /**
   * Process the form we have. Send to whatever backend
   * Only alerting for now
   onSubmit(form: FormGroup) { 
    const allInfo = 'fullname: '+form.value.fullname+' - email: '+form.value.email+' - phone: '+form.value.phone+' comm- ents: '+form.value.comments;
    alert(allInfo); 
  }
   */

  sendMail(form: FormGroup){
    //alert("jk");
    // let sendto  = "Farm Fresh Webmaster <enquiries@farmfreshmarket.net.au>";
    let sendto  = "Farm Fresh Webmaster <enquiries@farmfreshmarket.net.au>";
    let subject = "Enquiry from Farm Fresh Market Website";
    let email  = this.myForm.value.email;
    let name  = this.myForm.value.fullname;
    let phone  = this.myForm.value.phone;
    let comments  = this.myForm.value.comments;
    comments = '<p>' + comments.replace(/\n{2,}/g, '</p><p>').replace(/\n/g, '<br>') + '</p>';
    comments = '<h4>To Farm Fresh Market</h4>The following enquiry was received from the website:<hr>'+comments+'<hr><br>'+name+'<br>'+phone+'<br>'+email;
    let reqObj = {
      email:email,
      name:name,
      phone:phone,
      comments:comments,
      sendto:sendto,
      subject:subject
    }
    this.emailService.sendMessage(reqObj).subscribe(data=>{
      console.log(data);
    })
  }

}
