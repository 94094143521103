<section id="contact">
  <div class="container-fluid">
    <div class="well well-sm">
      <h2>Contact Us</h2>
    </div>
	
	<div class="row">
	  <div class="col-md-7 map">
			<iframe width="75%" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps/ms?msid=210324664347697622508.0004f74f8adb5bfb0d703&amp;msa=0&amp;hl=en&amp;ie=UTF8&amp;t=m&amp;z=13&amp;output=embed"></iframe>
      </div>

      <!-- contact form -->

      <div class="col-md-5 contactform">
        
          <h3>Get in Touch</h3>
        
        <div [hidden]="!isShowDiv" class="success"><h4>Your enquiry has been submitted to Farm Fresh Market.</h4><br><p>We will contact you soon.</p></div>

        <div [hidden]="isShowDiv">
        <form [formGroup]="myForm" (ngSubmit)="sendMail(myForm)">
          <div class="form-group">
            <input class="form-control" formControlName="fullname" placeholder="Your name">
            <div class="error" 
              *ngIf="myForm.get('fullname').invalid && (myForm.get('fullname').dirty || myForm.get('fullname').touched)">
              Please provide your name.
            </div>
          </div>
          <div class="form-group">
              <input class="form-control" formControlName="email" placeholder="Your email">
            <div class="error" 
              *ngIf="myForm.get('email').invalid && (myForm.get('email').dirty || myForm.get('email').touched)">
              Please provide your email address.
            </div>
          </div>
          <div class="form-group">
              <input class="form-control" formControlName="phone" placeholder="Your Pnone">
            <div class="error" 
              *ngIf="myForm.get('phone').invalid && (myForm.get('phone').dirty || myForm.get('phone').touched)">
              Please provide phone number.
            </div>
          </div>
          <div class="form-group">
              <textarea class="form-control" formControlName="comments" placeholder="Your Message"></textarea>
            <div class="error" 
              *ngIf="myForm.get('comments').invalid && (myForm.get('comments').dirty || myForm.get('comments').touched)">
              Please provide a message.
            </div>
          </div>
          <button type="submit" [disabled]="myForm.invalid" (click)=toggleDisplayDiv()> Send Enquiry </button>
        </form>
        </div>
      </div>
      <!-- // contact form -->
    </div>
  </div>
</section>
